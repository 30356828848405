<template>
  <div class="px-10">
    <div class="text-h4 primary--text">ค่าใช้จ่าย รายจ่ายพรบ. และประกัน</div>
    <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
      <div style="width: 300px">
        <v-select
          label="ชื่อ พ.ร.บ. และประกันภัย"
          v-model="filter.sub_category_id"
          :items="sub_category"
          item-value="_id"
          item-text="name"
          outlined
          rounded
          dense
          hide-details
        />
      </div>
      <v-spacer></v-spacer>
      <div style="width: 200px">
        <DatePicker
          label="วันที่"
          :value="filter.start_date"
          @onChange="(val) => (filter.start_date = val)"
          hide-details
          outlined
          rounded
          dense
        />
      </div>
      <v-spacer></v-spacer>
      <div style="width: 200px">
        <DatePicker
          label="ถึงวันที่"
          :value="filter.end_date"
          @onChange="(val) => (filter.end_date = val)"
          hide-details
          outlined
          rounded
          dense
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        small
        class="ml-5 px-10"
        @click="getData()"
        >ค้นหา</v-btn
      >
      <v-btn color="primary" small class="ml-5" @click="add()">
        <v-icon left small>mdi-plus</v-icon> เพิ่มรายจ่าย
      </v-btn>
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :search="search"
      :loading="loading"
      sort-by="col_a"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @click:row="edit"
    >
      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ formatDate(item.date_of_issue) }}
      </template>

      <template v-slot:[`item.net_price`]="{ item }">
        {{ parseFloat(item.net_price).toFixed(2) | numeral('0,0.00') }}
      </template>

      <template v-slot:[`item.actual_price`]="{ item }">
        {{ parseFloat(item.actual_price).toFixed(2) | numeral('0,0.00') }}
      </template>

      <template v-slot:[`item.commission_price`]="{ item }">
        {{ parseFloat(item.commission_price).toFixed(2) | numeral('0,0.00') }}
      </template>

      <template v-slot:[`item.payment`]="{ item }">
        {{ item.payment == 1 ? 'เงินสด' : '' }}
        {{ item.payment == 2 ? 'โอน' : '' }}
        {{ item.payment == 3 ? 'บัตรเครดิต' : '' }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="item.status == 0"
              :small="false"
              class="white--text"
              color="grey"
              v-bind="attrs"
              v-on="on"
            >
              <template><div>รอดำเนินการ</div></template>
              <v-icon small right>mdi-chevron-down</v-icon>
            </v-chip>
            <v-chip
              v-else-if="item.status == 1"
              :small="false"
              class="white--text"
              color="green"
              v-bind="attrs"
              v-on="on"
            >
              <template><div>จ่ายเงินแล้ว</div></template>
              <v-icon small right>mdi-chevron-down</v-icon>
            </v-chip>
            <v-chip
              v-else-if="item.status == 2"
              :small="false"
              class="white--text"
              color="error"
              v-bind="attrs"
              v-on="on"
            >
              <template><div>ยกเลิก</div></template>
              <v-icon small right>mdi-chevron-down</v-icon>
            </v-chip>
          </template>
          <v-list v-if="item.status != 2">
            <template
              v-for="payment in [
                { text: 'จ่ายเงิน', value: 10, color: '' },
                { text: 'ยกเลิก', value: 12, color: '' },
              ]"
            >
              <v-list-item
                :key="payment.value"
                link
                dense
                v-if="payment.value != item.status"
                @click="onSelected(item, payment.value)"
              >
                <v-list-item-title>{{ payment.text }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
    <div v-if="dialogExpensePayChoose">
      <v-dialog
        persistent
        scrollable
        max-width="600"
        v-model="dialogExpensePayChoose"
      >
        <ExpensePayChoose
          :item_id="item_selected"
          @onClose="(dialogExpensePayChoose = false), $emit('onClose')"
          @onSuccess="(dialogExpensePayChoose = false), getData()"
        ></ExpensePayChoose>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import ExpensePayChoose from '@/components/ExpensePayChoose';
import * as moment from 'moment';
import JsonExcel from 'vue-json-excel';

export default {
  components: {
    ExpensePayChoose,
    DatePicker,
  },

  data: () => ({
    search: '',
    loading: true,
    docTypeId: 6,
    dialogExpensePayChoose: false,
    item_selected: '',
    pageCount: 0,
    headers: [
      { text: 'วันที่', value: 'date_of_issue' },
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อ พ.ร.บ.', value: 'third_insurance_name' },
      { text: 'ชื่อประกัน', value: 'insurance_name', filterable: false },
      { text: 'ยอดชำระ', value: 'net_price', filterable: false },
      { text: 'ยอดที่จ่ายจริง', value: 'actual_price', filterable: false },
      { text: 'ค่าคอม', value: 'commission_price', filterable: false },
      { text: 'การชำระ', value: 'payment', filterable: false },
      { text: 'สถานะ', value: 'status', filterable: false },
      { text: '', value: 'actions', filterable: false },
    ],
    filter: {
      item_per_page: 10,
      page: 1,
      branch_id: '',
      sub_category_id: '',
      start_date: moment().add(-30, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    sub_category: [],
    list: [],
  }),

  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getSubCategoryList();
    this.getData();
  },

  methods: {
    add() {
      this.$router.push({
        name: 'expenses-insurance-current',
        params: { id: 1 },
      });
    },
    edit(item) {
      this.$router.push({
        name: 'expenses-insurance-current-update',
        params: { id: item._id },
      });
    },
    async remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { expense_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/expense/delete_expense`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      console.log(this.filter);
      await this.$axios
        .post(`${this.$baseUrl}/expense/get_insurance_expense`, body)
        .then((res) => {
          this.list = res.result.data;
          console.log(res.result);
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async getSubCategoryList() {
      let filter = {
        category_id: 'PC1'
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense_category/get_sub_category_list`, body)
        .then(async res => {
          this.sub_category.push({ _id: '', name: 'ทั้งหมด' });
          for(var i = 0; i < res.result.length; i++){
            this.sub_category.push(res.result[i])
          }
          this.filter.sub_category_id = this.sub_category[0]._id
        });
      this.getData()
    },
    // async getSubCategoryList() {
    //   let body = {
    //     token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
    //   };
    //   await this.$axios.post(`${this.$baseUrl}/expense/get_insurance_sub_category_list`, body)
    //     .then(async (res) => {
    //       this.sub_category.push({ _id: '', name: 'ทั้งหมด' });
    //       for (var i = 0; i < res.result.length; i++) {
    //         this.sub_category.push(res.result[i]);
    //       }
    //       this.filter.sub_category_id = this.sub_category[0]._id;
    //     });
    // },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    onSelected(item, status) {
      console.log(item._id);
      this.item_selected = item._id;
      if (status == 10) {
        console.log('dialogExpensePayChoose');
        this.dialogExpensePayChoose = true;
      } else if (status == 12) {
        this.cancel_expense(this.item_selected);
      }
    },
    cancel_expense(id) {
      this.$alertConfirm({ title: `ยืนยันยกเลิกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ expense_id: id }, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/expense/cancel_expense`, body)
              .then((res) => {
                this.$emit('success');
                this.getData();
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
